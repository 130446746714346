import { Component } from "@angular/core";

import { Platform, MenuController, ModalController, PopoverController } from "@ionic/angular";
import { DeviceDetectorService } from "ngx-device-detector";
import { SettingsComponent } from "./home/settings/settings.component";
import { AboutComponent } from "./home/about/about.component";
import { LanguageService } from "./services/language.service";
import { LanguageComponent } from './home/language/language.component';

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  // Translation strings
  label1: string;
  label2: string;
  label3: string;

  constructor(
    private platform: Platform,
    private menuCtrl: MenuController,
    private modalCtrl: ModalController,
    private languageSvc: LanguageService,
    private popoverCtrl: PopoverController,
    private deviceDetectorSvc: DeviceDetectorService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.menuCtrl.enable(!this.deviceDetectorSvc.isDesktop());
      this.languageSvc.Language.subscribe((_language) => {
        if (_language == "En") {
          this.label1 = "En";
          this.label2 = "Settings and filter";
          this.label3 = "About";
        } else {
          this.label1 = "De";
          this.label2 = "Einstellungen und Filter";
          this.label3 = "Über";
        }
      });
    });
  }

  onOpenSettings() {
    this.modalCtrl.create({ component: SettingsComponent }).then((_modalEl) => {
      _modalEl.present();
      this.menuCtrl.close();
    });
  }
  onOpenAbout() {
    this.modalCtrl.create({ component: AboutComponent }).then((_modalEl) => {
      _modalEl.present();
      this.menuCtrl.close();
    });
  }
  onLanguage() {
    this.popoverCtrl
      .create({ component: LanguageComponent })
      .then((_popoverEl) => {
        _popoverEl.present();
      });
  }
}
