import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ModalController, AlertController } from "@ionic/angular";
import { SettingsService } from "src/app/services/settings.service";
import { Settings } from "src/app/models/settings.model";
import { take } from "rxjs/operators";
import { DeviceDetectorService } from "ngx-device-detector";
import { LanguageService } from "src/app/services/language.service";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  settings = new Settings(
    "price",
    "order",
    "true",
    true,
    true,
    true,
    true,
    true,
    true
  );
  // Translation string
  title: string;
  sort: string;
  price: string;
  date: string;
  presentation: string;
  order: string;
  ratio: string;
  houses1: string;
  houses2: string;
  houses3: string;
  houses4: string;
  houses5: string;
  houses6: string;
  houses7: string;
  message: string;

  constructor(
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private settingsSvc: SettingsService,
    private languageSvc: LanguageService,
    private deviceDetectorSvc: DeviceDetectorService
  ) {}

  ngOnInit() {
    this.languageSvc.Language.pipe(take(1)).subscribe((_language) => {
      if (_language == "En") {
        this.title = "Settings and Filter";
        this.sort = "Sort by";
        this.price = "Purchase price";
        this.date = "Date of purchase";
        this.presentation = "Visual representation by";
        this.order = "Order";
        this.ratio = "Ratio";
        this.houses1 = "Single-, Two-family houses";
        this.houses2 = "Rented apartment buildings";
        this.houses3 = "Empty apartment buildings";
        this.houses4 = "Non-residential buildings";
        this.houses5 = "Demolition buildings";
        this.houses6 = "Undeveloped property";
        this.houses7 = "Others";
        this.message =
          "Order: When using this calculation, all buildings are put in order " +
          "according to the selected criteria (e.g. price). The color value will be assigned to the buildings depending on their place in this series." +
          "<br/><br/>Ratio: Here the colors are calculated with the value of the selected criteria (e.g. price) in ratio to the maximum value of the criteriea from all buildings." +
          "<br/><br/>Color legend<br/>Purchase price: Red - High price; Green - Low price<br/>Purchase-date: Red - Older; Green - Younger";
      } else {
        this.title = "Einstellungen und Filter";
        this.sort = "Sortieren nach";
        this.price = "Kaufpreis";
        this.date = "Erwerbsdatum";
        this.presentation = "Visuelle Darstellung nach";
        this.order = "Reihung";
        this.ratio = "Verhältnis";
        this.houses1 = "Ein-, Zweifamilienhäuser";
        this.houses2 = "Mietwohnhäuser vermietet";
        this.houses3 = "Mietwohnhäuser leer";
        this.houses4 = "Betriebsobjekte";
        this.houses5 = "Abbruchobjekte";
        this.houses6 = "Unbebautes Grundstück";
        this.houses7 = "Sonstiges";
        this.message =
          "Reihung: Bei dieser Berechnung werden alle Gebäude nach ausgewähltem " +
          "Kriterium (z.B. Preis) gereiht und der Farbwert nach Platzierung in der Reihenfolge zugewiesen." +
          "<br/><br/>Verhältnis: Hierbei werden die Farben mithilfe des Wertes des Kriteriums (z.B. Preis) des aktuellen Gebäudes im relativen Vergleich zum höchsten Wert des Kriteriums aller Gebäude zugeteilt." +
          "<br/><br/>Farblegende: <br/>Preis: Rot - Hoher Preis; Grün - Niedriger Preis<br/> Kaufdatum: Rot - Älter; Grün - Jünger";
      }
    });
  }

  ionViewWillEnter() {
    this.settingsSvc.Settings.pipe(take(1)).subscribe((_settings) => {
      this.settings = _settings;
    });
  }

  onInfo() {
    this.alertCtrl
      .create({
        header: "Information",
        buttons: ["Okay"],
        message: this.message,
        cssClass: !this.deviceDetectorSvc.isMobile() ? "custom-alert" : "",
      })
      .then((_alertEl) => {
        _alertEl.present();
      });
  }

  onSaveSettings(form: NgForm) {
    this.settingsSvc.SetSettings(form.value);
    this.modalCtrl.dismiss();
  }

  onCancel() {
    this.modalCtrl.dismiss();
  }
}
