import { Component, OnInit } from "@angular/core";
import { LanguageService } from "src/app/services/language.service";
import { PopoverController } from "@ionic/angular";
import { take } from "rxjs/operators";

@Component({
  selector: "app-language",
  templateUrl: "./language.component.html",
  styleUrls: ["./language.component.scss"],
})
export class LanguageComponent implements OnInit {
  header: string;
  constructor(
    private popOverCtrl: PopoverController,
    private languageSvc: LanguageService
  ) {}

  ngOnInit() {
    this.languageSvc.Language.pipe(take(1)).subscribe((_language) => {
      if (_language == "En") {
        this.header = "Select Language";
      } else {
        this.header = "Sprache auswählen";
      }
    });
  }

  selectLang(_language) {
    this.languageSvc.SetLanguage(_language);
    this.popOverCtrl.dismiss();
  }
}
