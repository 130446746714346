import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  private language = new BehaviorSubject<"En" | "De">("En");

  get Language() {
    return this.language.asObservable();
  }
  public SetLanguage(_language: "En" | "De") {
    this.language.next(_language);
  }
  constructor() {}
}
