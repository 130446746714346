import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Settings } from '../models/settings.model';

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  private settings = new BehaviorSubject<Settings>(
    new Settings("price", "order", "true", true, true, true, true, true, true)
  );

  get Settings() {
    return this.settings.asObservable();
  }
  public SetSettings(_settings: Settings) {
    this.settings.next(_settings);
  }

  constructor() {}
}
