export class Settings {
  public sort: "price" | "age";
  public calc: "order" | "ratio";
  public house: Boolean;
  public rent1: Boolean;
  public rent0: Boolean;
  public company: Boolean;
  public land: Boolean;
  public trash: Boolean;
  public else: Boolean;

  constructor(
    _sort,
    _calc,
    _house,
    _rent1,
    _rent0,
    _company,
    _land,
    _trash,
    _else
  ) {
    this.sort = _sort;
    this.calc = _calc;
    this.house = _house;
    this.rent1 = _rent1;
    this.rent0 = _rent0;
    this.company = _company;
    this.land = _land;
    this.trash = _trash;
    this.else = _else;
  }
}
